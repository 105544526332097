.main_container_bottom_nav{
    display: grid;
    grid-template-columns: 60% 20% 20%;
    padding-bottom: 25px;
    padding-left: 75px;
    padding-right: 75px;
    text-align: right;
    position: sticky;
    margin-top: 200px;
    right: 0px;
    width: 100%;
    bottom: 0px;

}

.mother_container_bottom_nav{

    display: block;
    overflow-x: hidden;
}

@media(min-width: 1600px){

    .main_container_bottom_nav{
        grid-template-columns: 80% 10% 10%;
    }
}




@media(max-width: 1000px){

    .main_container_bottom_nav {

        padding-left: 50px;
        padding-right: 50px;
    }
}

@media(max-width: 800px) {

    .main_container_bottom_nav{
       
        grid-template-columns: 100%;
        padding-top: 40px;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;
    
    }


    .p_legal {
        font-weight: 300;
        font-size: 16px;
    }
}

